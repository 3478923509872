/**
 * Utility Functions
 * Bootstrap Templates
 */
const mrcJSUtil = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';

	function uniqueUrl (url) {
		return (url +  qoramp(url) + 'uid=' + new Date().getTime());
	}

	function qoramp (url) {
		if (!url) return;

		if (url.indexOf('?') > 0) return '&';

		return '?';
	}

	function addParm (url, parm, value) {
		return url + qoramp(url) + parm + '=' + value;
	}
	/**Get the values of all inputs on the page with an ID**/
	function getInputValues () {
		var nameValueObj = {}
		jQuery(":input[id]").each(function(){
			nameValueObj[jQuery(this).attr('id')] = jQuery(this).val();
		});
		return nameValueObj;
	}
	return {
		uniqueUrl: uniqueUrl,
		addParm: addParm,
        qoramp: qoramp,
        getInputValues:getInputValues
	};
}(window, jQuery));
