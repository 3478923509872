/**
 *
 */
const appInit = (function (window, $) { // eslint-disable-line no-unused-vars
	'use strict';

	/* global columnToggle, resetForm */
	function initializeForms ($html) {
		const $forms =formHandler.getFormFrom$HTML($html);// ($html ? $html.find('form') : $('form'));

		$forms.each(function callResetForm (i, el) {
			// @ts-ignore
			resetForm(el);
            columnToggle.setColumnsSelected(el);
            jQuery(el).closest('.ajax_content').trigger('mrc:pageInitialized');		
		});
	}

	/* global calendarPickers, formInputToggle, maintainerDetailView, mrcBootstrapJS,mrcFilters, mrcCalendar, mrcJqueryPlugins, mrcSorting, mrcValidation, reportPrompt, selectionDialog,fullTextSearch */
	function initializePage ($html) {
		mrcSorting.initializeAllArrowsForSort($html);
		selectionDialog.initializeAllSelectionDialogs($html);
		reportPrompt.setPromptInputs();
		mrcBootstrapJS.setBootstrapJs();
		formInputToggle.setFormToggleEvents($html);
		mrcJqueryPlugins.initJqueryPlugins();
		initializeForms($html);
		mrcCalendar.setupCalendarForms($html);
		mrcValidation.initFormValidation($html);
		ckeditorConfig.initCkeditor($html);
		fullTextSearch.initFullTextSearch($html);
        mrcFilters.setRelationshipValues($html);	
        calendarPickers.setupCalendarPickers($html);
        mrcNotification.initializeNotificationsBadge($html);
        mrcSignatureCapture.initSignatureCapture($html);
        mrcGeoMaps.loadGeoMaps($html);
        //maintainerDetailView.setDetailViewEvents($html);

		// Pressing Enter on a list-group-item should be the same as clicking on it (WCAG).
		jQuery('.list-group-item').on('keypress', function(evt) {  
			if (evt.which == 13) { evt.target.click(); }
		});		
		// Pressing Enter on a drill_icon should be the same as clicking on it (WCAG).
		jQuery('.drill_icon').on('keypress', function(evt) {
			if (evt.which == 13) { evt.target.click(); }
		});
		// Set focus on first editable field whenever new content is added to the interface (WCAG).
		jQuery('body').on('mrc:detailloaded', function(ev) {
			jQuery('#mrcMainContent form').find(':input:enabled:visible:first').focus();
		});      				  
	}

	function initializePageAfterAJAXRefresh (ev) {
		var $html = $(ev.target);
        initializePage($html);
        maintainerDetailView.setDetailViewEvents($html);
	}

	$('body').on('mrc:contentLoaded', initializePageAfterAJAXRefresh)
		.on('change', formInputToggle.FILE_INPUT_SELECTOR, formInputToggle.updateFileText);

	initializePage();

	return {
		initializePage: initializePage
	};
}(window, jQuery));